var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h6" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.contactType) + " Contacts\n      "
                )
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex align-center justify-center",
                          attrs: { cols: "2", md: "1" }
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "px-1",
                                            attrs: {
                                              color: "primary",
                                              fab: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.downloadSampleContactFile()
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-file-delimited")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Download " +
                                    _vm._s(_vm.contactType) +
                                    " Contacts Template"
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "10", md: "4", lg: "3" } },
                        [
                          _c("v-file-input", {
                            attrs: {
                              "error-messages": _vm.contactFileErrors,
                              dense: "",
                              clearable: "",
                              rules: _vm.contactFileRules,
                              accept: _vm.testContactExtensionsString,
                              label: "Upload " + _vm.contactType + " Contacts"
                            },
                            on: {
                              change: function($event) {
                                _vm.contactFileErrors = []
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        disabled: !_vm.contactFile,
                                                        small: "",
                                                        icon: "",
                                                        color: "success"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.validateUpload()
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-upload")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Upload selected file")
                                        ])
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.contactFile,
                              callback: function($$v) {
                                _vm.contactFile = $$v
                              },
                              expression: "contactFile"
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "font-italic pl-7 inline-block" },
                            [
                              _vm._v(
                                "\n              Valid file extensions: " +
                                  _vm._s(_vm.testContactExtensionsString) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              Any personalizations left blank will use the Default Test Personalizations\n            "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "py-0 d-flex align-center justify-center",
                          attrs: { cols: "12", md: "1" }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "font-italic text-subtitle-1" },
                            [_vm._v("OR")]
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", lg: "7" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              "item-text": "email",
                              "item-value": "email",
                              items: _vm.customerEmails,
                              multiple: "",
                              dense: "",
                              "hide-selected": "",
                              "deletable-chips": "",
                              rules: _vm.emailValuesRules,
                              "error-messages": _vm.customerEmailErrors,
                              "search-input": _vm.customerEmailSearch,
                              loading: _vm.tenantContactsLoading,
                              label:
                                "Select " +
                                _vm.contactType +
                                " Contacts or Add New",
                              color: "primary",
                              counter: "",
                              "small-chips": "",
                              "auto-select-first": "",
                              "hide-no-data": ""
                            },
                            on: {
                              "update:searchInput": function($event) {
                                _vm.customerEmailSearch = $event
                              },
                              "update:search-input": function($event) {
                                _vm.customerEmailSearch = $event
                              },
                              change: function($event) {
                                _vm.customerEmailSearch = null
                                _vm.customerEmailErrors = null
                              },
                              keyup: [
                                function($event) {
                                  return _vm.checkIfEmailValid()
                                },
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.appendNewTestEmail(
                                    _vm.customerEmailSearch
                                  )
                                },
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "space",
                                      32,
                                      $event.key,
                                      [" ", "Spacebar"]
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.appendNewTestEmail(
                                    _vm.customerEmailSearch
                                  )
                                },
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    $event.keyCode !== 186
                                  ) {
                                    return null
                                  }
                                  return _vm.appendNewTestEmail(
                                    _vm.customerEmailSearch
                                  )
                                }
                              ]
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "d-flex" },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          { staticClass: "d-flex" },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(item.email) +
                                                "\n                    "
                                            ),
                                            item.firstName || item.lastName
                                              ? _c(
                                                  "v-list-item-subtitle",
                                                  { staticClass: "ml-2" },
                                                  [
                                                    _vm._v(
                                                      "\n                      (" +
                                                        _vm._s(
                                                          (
                                                            (item.firstName ||
                                                              "") +
                                                            " " +
                                                            (item.lastName ||
                                                              "")
                                                          ).trim()
                                                        ) +
                                                        ")\n                    "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.emailList,
                              callback: function($$v) {
                                _vm.emailList = $$v
                              },
                              expression: "emailList"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h6" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.contactType) +
                    " Personalizations\n      "
                )
              ]),
              _c("v-card-subtitle", { staticClass: "font-italic" }, [
                _vm._v(
                  "\n        These values will be substituted for your personalizations on your " +
                    _vm._s(_vm.contactType) +
                    " Contacts.\n      "
                )
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.contactsTableHeaders,
                      items: _vm.contacts,
                      "items-per-page": 5,
                      loading: _vm.mailingContactsLoading
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "body.prepend",
                        fn: function() {
                          return [
                            _vm.getTargetId() !== null &&
                            _vm.getPersonalizations().length > 0
                              ? _c(
                                  "tr",
                                  [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-right",
                                        attrs: {
                                          colspan: _vm.staticTableHeaders.length
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-medium" },
                                          [_vm._v("Default:")]
                                        )
                                      ]
                                    ),
                                    _vm._l(_vm.getPersonalizations(), function(
                                      pers
                                    ) {
                                      return _c(
                                        "td",
                                        { key: pers.name },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              dense: "",
                                              "hide-details": "",
                                              placeholder: pers.name
                                            },
                                            model: {
                                              value:
                                                _vm.defaultItem[
                                                  _vm.personalizationToUnderscores(
                                                    pers.name.toLowerCase()
                                                  )
                                                ],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.defaultItem,
                                                  _vm.personalizationToUnderscores(
                                                    pers.name.toLowerCase()
                                                  ),
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "defaultItem[personalizationToUnderscores(pers.name.toLowerCase())]"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.getTargetAudienceStatus() ===
                              _vm.$_taStatus_Completed &&
                            _vm.getPersonalizations().length > 0
                              ? _c(
                                  "tr",
                                  [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-right",
                                        attrs: {
                                          colspan: _vm.staticTableHeaders.length
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-medium" },
                                          [
                                            _vm._v(
                                              "Target Audience Personalization Coverage (%):"
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._l(_vm.getPersonalizations(), function(
                                      pers
                                    ) {
                                      return _c(
                                        "td",
                                        { key: pers.name },
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                color: _vm.getPersonalizationCoverageColor(
                                                  pers
                                                ),
                                                "text-color": "white"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    pers.audienceCoverage
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "item.action",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: { small: "", color: "success" },
                                on: {
                                  click: function($event) {
                                    return _vm.editPersonalization(item)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              mdi-pencil\n            "
                                )
                              ]
                            ),
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  right: "",
                                  "x-offset": "",
                                  origin: "right",
                                  transition: "slide-x-transition"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  small: "",
                                                  color: "error"
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                  mdi-delete\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "v-card",
                                  [
                                    _c("v-card-text", { staticClass: "pa-5" }, [
                                      _c("span", [
                                        _vm._v(
                                          "Are you sure you want to delete this " +
                                            _vm._s(_vm.contactType) +
                                            " Contact?"
                                        )
                                      ])
                                    ]),
                                    _c("v-divider"),
                                    _c(
                                      "v-card-actions",
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "tertiary",
                                              disabled: _vm.isLoading()
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Cancel\n                  "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "error",
                                              loading: _vm.isLoading()
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeContact(item)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Yes\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c(
                    "v-dialog",
                    {
                      attrs: { "max-width": "700px", persistent: "" },
                      model: {
                        value: _vm.editPersonalizationsDialog,
                        callback: function($$v) {
                          _vm.editPersonalizationsDialog = $$v
                        },
                        expression: "editPersonalizationsDialog"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass: "success white--text",
                              attrs: { "primary-title": "" }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "text-h5 font-italic" },
                                [_vm._v("Edit Personalizations")]
                              )
                            ]
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Email",
                                              readonly: ""
                                            },
                                            model: {
                                              value: _vm.editedItem.email,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression: "editedItem.email"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "First Name (FNAME)"
                                            },
                                            model: {
                                              value: _vm.editedItem.firstName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "firstName",
                                                  $$v
                                                )
                                              },
                                              expression: "editedItem.firstName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Last Name (LNAME)"
                                            },
                                            model: {
                                              value: _vm.editedItem.lastName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "lastName",
                                                  $$v
                                                )
                                              },
                                              expression: "editedItem.lastName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "4"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: { label: "Title (MDDO)" },
                                            model: {
                                              value: _vm.editedItem.mddo,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "mddo",
                                                  $$v
                                                )
                                              },
                                              expression: "editedItem.mddo"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._l(
                                        _vm.getPersonalizations(),
                                        function(pers) {
                                          return _c(
                                            "v-col",
                                            {
                                              key: pers.name,
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4"
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: { label: pers.name },
                                                on: {
                                                  keyup: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.savePersonalization.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedItem[
                                                      pers.name.toLowerCase()
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedItem,
                                                      pers.name.toLowerCase(),
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedItem[pers.name.toLowerCase()]"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "tertiary" },
                                  on: { click: _vm.closePersonalizationsDialog }
                                },
                                [
                                  _vm._v(
                                    "\n                Cancel\n              "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "success",
                                    loading: _vm.savingPersonalization
                                  },
                                  on: { click: _vm.savePersonalization }
                                },
                                [
                                  _vm._v(
                                    "\n                Save\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }