<template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-card>
        <v-card-title class="text-h6">
          {{ contactType }} Contacts
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="2"
              md="1"
              class="d-flex align-center justify-center"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    fab
                    class="px-1"
                    v-on="on"
                    @click="downloadSampleContactFile()"
                  >
                    <v-icon>mdi-file-delimited</v-icon>
                  </v-btn>
                </template>
                <span>Download {{ contactType }} Contacts Template</span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="10"
              md="4"
              lg="3"
            >
              <v-file-input
                v-model="contactFile"
                :error-messages="contactFileErrors"
                dense
                clearable
                :rules="contactFileRules"
                :accept="testContactExtensionsString"
                :label="`Upload ${contactType} Contacts`"
                @change="contactFileErrors = []"
              >
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :disabled="!contactFile"
                        small
                        icon
                        color="success"
                        v-on="on"
                        @click="validateUpload()"
                      >
                        <v-icon>mdi-upload</v-icon>
                      </v-btn>
                    </template>
                    <span>Upload selected file</span>
                  </v-tooltip>
                </template>
              </v-file-input>
              <div
                class="font-italic pl-7 inline-block"
              >
                Valid file extensions: {{ testContactExtensionsString }}
                <br>
                Any personalizations left blank will use the Default Test Personalizations
              </div>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="py-0 d-flex align-center justify-center"
            >
              <span class="font-italic text-subtitle-1">OR</span>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="7"
            >
              <v-autocomplete
                v-model="emailList"
                item-text="email"
                item-value="email"
                :items="customerEmails"
                multiple
                dense
                hide-selected
                deletable-chips
                :rules="emailValuesRules"
                :error-messages="customerEmailErrors"
                :search-input.sync="customerEmailSearch"
                :loading="tenantContactsLoading"
                :label="`Select ${contactType} Contacts or Add New`"
                color="primary"
                counter
                small-chips
                auto-select-first
                hide-no-data
                @change="customerEmailSearch = null; customerEmailErrors = null"
                @keyup="checkIfEmailValid()"
                @keyup.enter="appendNewTestEmail(customerEmailSearch)"
                @keyup.space="appendNewTestEmail(customerEmailSearch)"
                @keyup.186="appendNewTestEmail(customerEmailSearch)"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content class="d-flex">
                    <v-list-item-title class="d-flex">
                      {{ item.email }}
                      <v-list-item-subtitle
                        v-if="item.firstName || item.lastName"
                        class="ml-2"
                      >
                        ({{ `${item.firstName || ''} ${item.lastName || ''}`.trim() }})
                      </v-list-item-subtitle>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
    >
      <v-card>
        <v-card-title class="text-h6">
          {{ contactType }} Personalizations
        </v-card-title>
        <v-card-subtitle
          class="font-italic"
        >
          These values will be substituted for your personalizations on your {{ contactType }} Contacts.
        </v-card-subtitle>
        <v-card-text>
          <v-data-table
            :headers="contactsTableHeaders"
            :items="contacts"
            :items-per-page="5"
            :loading="mailingContactsLoading"
          >
            <template v-slot:body.prepend>
              <tr v-if="getTargetId() !== null && getPersonalizations().length > 0">
                <td
                  :colspan="staticTableHeaders.length"
                  class="text-right"
                >
                  <span class="font-weight-medium">Default:</span>
                </td>
                <td
                  v-for="pers in getPersonalizations()"
                  :key="pers.name"
                >
                  <v-text-field
                    v-model="defaultItem[personalizationToUnderscores(pers.name.toLowerCase())]"
                    dense
                    hide-details
                    :placeholder="pers.name"
                  />
                </td>
              </tr>
              <tr v-if="getTargetAudienceStatus() === $_taStatus_Completed && getPersonalizations().length > 0">
                <td
                  :colspan="staticTableHeaders.length"
                  class="text-right"
                >
                  <span class="font-weight-medium">Target Audience Personalization Coverage (%):</span>
                </td>
                <td
                  v-for="pers in getPersonalizations()"
                  :key="pers.name"
                >
                  <v-chip
                    small
                    :color="getPersonalizationCoverageColor(pers)"
                    text-color="white"
                  >
                    {{ pers.audienceCoverage }}
                  </v-chip>
                </td>
              </tr>
            </template>
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2"
                color="success"
                @click="editPersonalization(item)"
              >
                mdi-pencil
              </v-icon>
              <v-menu
                right
                x-offset
                origin="right"
                transition="slide-x-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    color="error"
                    v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>

                <v-card>
                  <v-card-text class="pa-5">
                    <span>Are you sure you want to delete this {{ contactType }} Contact?</span>
                  </v-card-text>

                  <v-divider />

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="tertiary"
                      :disabled="isLoading()"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="error"
                      :loading="isLoading()"
                      @click="removeContact(item)"
                    >
                      Yes
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </template>
          </v-data-table>
          <v-dialog
            v-model="editPersonalizationsDialog"
            max-width="700px"
            persistent
          >
            <v-card>
              <v-card-title
                class="success white--text"
                primary-title
              >
                <span class="text-h5 font-italic">Edit Personalizations</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                        readonly
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.firstName"
                        label="First Name (FNAME)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.lastName"
                        label="Last Name (LNAME)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem.mddo"
                        label="Title (MDDO)"
                      />
                    </v-col>
                    <v-col
                      v-for="pers in getPersonalizations()"
                      :key="pers.name"
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="editedItem[pers.name.toLowerCase()]"
                        :label="pers.name"
                        @keyup.enter="savePersonalization"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="tertiary"
                  @click="closePersonalizationsDialog"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="success"
                  :loading="savingPersonalization"
                  @click="savePersonalization"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue'
import { downloadFileMixin, enumsMixin } from '@/shared/mixins/general'
import { mapGetters, mapActions } from 'vuex'
import { contactService, mailingService } from '@/shared/services'
import { fileExtensions } from '@/shared/models/content-library'

export default {
  name: 'ContactUploadTable',

  mixins: [downloadFileMixin, enumsMixin],

  props: {
    test: {
      type: Boolean,
      default: false
    },
    finalBroadcast: {
      type: Boolean,
      default: false
    }
  },

  data: vm => ({
    customerEmailSearch: null,
    customerEmailErrors: null,
    customerEmails: [],
    tenantContactsLoading: false,
    mailingContactsLoading: false,
    emailValuesRules: [
      value =>
        !value ||
          value.length <= 100 ||
          'Cannot proceed with more than 100 test/final broadcast contacts'
    ],
    contactFile: null,
    contactFileErrors: [],
    contactFileUploading: false,
    contactFileRules: [
      value =>
        !value ||
          value.size < 10000 ||
          `${vm.contactType} Contact size should be less than 10 KB`
    ],
    defaultItem: {
      email: '',
      fname: '',
      lname: '',
      name: ''
    },
    editPersonalizationsDialog: false,
    savingPersonalization: false,
    editedItem: {},
    editedIndex: -1,
    unwatchPersonalizations: []
  }),

  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('mailing', ['getPersonalizations', 'getMailingItemId', 'getMailingTenantCrmId', 'getContacts', 'getTargetId', 'getTargetAudienceStatus']),
    contactType () {
      if (this.test) {
        return 'Test'
      }
      if (this.finalBroadcast) {
        return 'Final Broadcast'
      }
      return ''
    },
    staticTableHeaders () {
      return [
        { text: 'Actions', value: 'action', sortable: false, filterable: false },
        { text: 'Email', value: 'email' },
        { text: 'First Name (FNAME)', value: 'firstName' },
        { text: 'Last Name (LNAME)', value: 'lastName' },
        { text: 'Title (MDDO)', value: 'mddo' }
      ]
    },
    contactsTableHeaders () {
      return [
        ...this.staticTableHeaders,
        ...this.getPersonalizations().map(x => {
          return { text: x.name, value: x.name.toLowerCase() }
        })
      ]
    },
    contacts () {
      return this.getContacts()
    },
    emailList: {
      get () {
        return this.contacts.map(x => x.email)
      },
      set (val) {
        if (this.isEmailValid(this.customerEmailSearch)) {
          this.appendNewTestEmail(this.customerEmailSearch)
          return
        }
        let added = val.filter(x => !this.contacts.map(y => y.email).includes(x))
        added.forEach((email, index) => {
          let contact = this.customerEmails.find(x => x.email.toLowerCase() === email.toLowerCase())
          this.addContact(contact.id, email, false)
        })
        let removed = this.contacts.filter(x => !val.includes(x.email))
        removed.forEach((item, index) => {
          this.removeContact(item)
        })
      }
    },
    testContactExtensionsString () {
      return fileExtensions.getExtensionsString(fileExtensions.testContactExtensionsList)
    }
  },

  created () {
    this.init()
  },

  methods: {
    ...mapActions('mailing', ['setDefaultPersonalizationValue', 'addContactToMailing', 'removeContactFromMailing', 'setContactPersonalizations', 'loadContacts']),
    async init () {
      await this.loadAllContacts()
    },
    destroy () {
      this.removePersonalizationWatchers()
    },
    removePersonalizationWatchers () {
      this.unwatchPersonalizations.forEach(unwatch => {
        unwatch()
      })
    },
    checkIfEmailValid () {
      if (this.isEmailValid(this.customerEmailSearch)) { this.customerEmailErrors = null }
    },
    isEmailValid (email) {
      return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(email)
    },
    personalizationToUnderscores (pers) {
      return pers.replace(/ /g, '_')
    },
    personalizationToSpaces (pers) {
      return pers.replace(/_/g, ' ')
    },
    async loadAllContacts () {
      await this.loadTenantContacts()
      await this.loadMailingContacts()
    },
    async loadMailingContacts () {
      this.mailingContactsLoading = true
      this.removePersonalizationWatchers()
      var mic = {
        mailingItemId: this.getMailingItemId(),
        receiveTest: this.test,
        receiveFinalBroadcast: this.finalBroadcast
      }
      await this.loadContacts(mic)
      this.getPersonalizations().forEach((item, index) => {
        let pers_ = this.personalizationToUnderscores(item.name.toLowerCase())
        Vue.set(this.defaultItem, pers_, item.testValue)
        this.unwatchPersonalizations.push(this.$watch(`defaultItem.${pers_}`, function (newValue, oldValue) {
          this.setDefaultPersonalizationValue({ id: item.id, testValue: newValue })
        }))
      })
      this.mailingContactsLoading = false
    },
    async loadTenantContacts () {
      this.tenantContactsLoading = true
      this.customerEmails = await contactService.getTenantContacts(this.getMailingTenantCrmId())
      this.tenantContactsLoading = false
    },
    async downloadSampleContactFile () {
      var _this = this
      await mailingService
        .downloadSampleContactFile(this.getTargetId() || '')
        .then(function (resp) {
          _this.$_downloadFileMixin_downloadResponse(resp)
        })
    },
    async appendNewTestEmail (search) {
      if (!search) return
      search = search.trim().toLowerCase()
      // if it is already on the list of selectable contacts and user is using typing to select => return
      if (this.customerEmails.some(x => x.email.toLowerCase().includes(search)) && !this.contacts.some(x => x.email.toLowerCase().endsWith(search))) {
        return
      }
      // if email invalid => return
      if (!this.isEmailValid(search)) {
        this.customerEmailErrors = 'Must be a valid email'
        return
      }
      // if email is already on list of selected contacts => return
      if (search && this.contacts.some(x => x.email.toLowerCase() === search)) {
        this.customerEmailErrors = `${search} is already selected`
        return
      }

      var contactId = ''
      if (this.customerEmails.some(x => x.email.toLowerCase() === search)) {
        contactId = this.customerEmails.find(x => x.email.toLowerCase() === search).id
      } else {
        contactId = await contactService.createContact({ email: search, tenantCrmId: this.getMailingTenantCrmId() })
      }

      await this.addContact(contactId, search, true)
      this.customerEmailSearch = null
    },
    async addContact (contactId, email, isNew) {
      this.mailingContactsLoading = true
      let contact = Object.assign({}, this.defaultItem,
        { contactId: contactId,
          mailingItemId: this.getMailingItemId(),
          email: email.toLowerCase(),
          receiveTest: this.test,
          receiveFinalBroadcast: this.test || this.finalBroadcast
        })
      if (isNew) {
        this.customerEmails.push(contact)
      }
      var result = await this.addContactToMailing(contact)
      this.mailingContactsLoading = false
    },
    async validateUpload () {
      var reader = new FileReader()
      reader.readAsText(this.contactFile)
      reader.onload = async () => {
        var contents = reader.result
        var rows = contents.split('\r\n')
        const totalRows = rows.length
        const lastRowIndex = totalRows - 1
        const emptyRowRegex = /^\s*,+\s*$/
        const headers = rows[0].split(',')
        const emailSet = new Set()
        var emailColumnIndex = -1
        // Find the email column index by comparing with known email header
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i].trim()
          if (header.toLowerCase() === 'email') {
            emailColumnIndex = i
            break
          }
        }
        if (emailColumnIndex === -1) {
        // Email column not found
          this.contactFileErrors.push('Email column not found')
        } else {
          for (let i = 0; i < totalRows; i++) {
            const row = rows[i]
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
            if (i === lastRowIndex && row.trim() === '') {
              continue
            }
            if (row.trim() === '' || emptyRowRegex.test(row)) {
              // Empty row found
              this.contactFileErrors.push('File contains empty rows')
              break
            }
            const columns = row.split(',')
            const email = columns[emailColumnIndex].trim()
            if (emailSet.has(email.toLowerCase())) {
            // Duplicate email found
              this.contactFileErrors.push('Duplicate email found')
              break // Exit the loop after the first duplicate email
            }

            if (!emailRegex.test(email) && (email !== 'Email')) {
              // Invalid email format found
              this.contactFileErrors.push('Invalid email address found')
              break
            }

            emailSet.add(email)
          }
        }

        if (this.contactFileErrors.length === 0) {
          return this.uploadContacts()
        }
      }
    },
    async uploadContacts () {
      this.contactFileErrors = []
      let formData = new FormData()
      formData.append('mailingId', this.getMailingItemId())
      formData.append('receiveTest', this.test)
      formData.append('receiveFinalBroadcast', this.test || this.finalBroadcast)
      formData.append('files[]', this.contactFile)
      this.$store.commit('setLoadingMessage', `Uploading ${this.contactType} contacts`)
      await contactService.uploadContacts(formData)
        .then(resp => {
          if (resp.errorResponse) {
            this.contactFileErrors.push(resp.errorResponse)
          } else {
            this.loadAllContacts()
            this.contactFile = null
          }
        })
    },
    editPersonalization (item) {
      this.editedIndex = this.contacts.indexOf(item)
      this.editedItem = JSON.parse(JSON.stringify(item))
      this.editPersonalizationsDialog = true
    },
    getPersonalizationCoverageColor (pers) {
      if (pers.audienceCoverage >= 100) return 'green'
      if (pers.audienceCoverage >= 75) return 'light-green'
      if (pers.audienceCoverage >= 50) return 'amber'
      if (pers.audienceCoverage >= 25) return 'orange'
      if (pers.audienceCoverage > 0) return 'deep-orange'
      if (pers.audienceCoverage <= 0) return 'red'
    },
    async removeContact (item) {
      this.mailingContactsLoading = true
      var mic = {
        mailingItemId: this.getMailingItemId(),
        contactId: item.contactId,
        receiveTest: !this.test,
        receiveFinalBroadcast: !this.finalBroadcast && !this.test
      }
      await this.removeContactFromMailing(mic)
      this.mailingContactsLoading = false
    },
    closePersonalizationsDialog () {
      this.editPersonalizationsDialog = false
      this.savingPersonalization = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    async savePersonalization () {
      this.savingPersonalization = true
      await this.setContactPersonalizations(this.editedItem)
      await this.loadTenantContacts()
      this.closePersonalizationsDialog()
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
